import React from 'react';

export default function Fruits() {
return (
<div className="eth-artefact-set">
    <div className="eth-artefact-set-inner">
        <div className="eth-artefact-set-frame">
            <div className="eth-artefact-set-frame-img">
                <img src="../../images/eth-gold-frame.png" alt="Gold frame" />
            </div>
            <div className="eth-artefact-set-frame-content">
                <div className="eth-artefact-set-frame-content-top">
                    <div className="eth-artefact-set-frame-content-top-left">
                        <div className="eth-artefact-set-frame-tokens">
                            <img src="../../images/eth-fruits-tokens.png" alt="Forbidden Fruits tokens" />
                        </div>
                        <div className="eth-artefact-set-frame-variations text-center">
                            <div className="eth-artefact-set-frame-variations-text">
                                Variations in the artefact set
                            </div>
                            <div className="eth-artefact-set-frame-variations-icons">
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-fruits-token-icon-1.png" alt="Forbidden Fruits artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        5
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-fruits-token-icon-2.png" alt="Forbidden Fruits artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        10
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-fruits-token-icon-3.png" alt="Forbidden Fruits artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        10
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-fruits-token-icon-4.png" alt="Forbidden Fruits artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        10
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-fruits-token-icon-5.png" alt="Forbidden Fruits artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        10
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-fruits-token-icon-6.png" alt="Forbidden Fruits artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        5
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-content-top-right">
                        <div className="eth-artefact-set-frame-counter">
                            <div className="eth-artefact-set-frame-counter-img">
                                <img src="../../images/buypage_tokencounter_keret.png" alt="Artefact counter frame" />
                            </div>
                            <div className="eth-artefact-set-frame-counter-data text-center">
                                <div className="eth-artefact-set-frame-counter-data-number eth-text-bright-yellow">
                                    50
                                </div>
                                <div className="eth-artefact-set-frame-counter-data-text">
                                    artefacts
                                </div>
                            </div>
                        </div>
                        <div className="eth-artefact-set-frame-tokens-data text-center">
                            <div className="eth-artefact-set-frame-tokens-data-serial">
                                Serial Numbers: 093-044
                            </div>
                            <div className="eth-artefact-set-frame-tokens-data-utility">
                                Utility traits: 5
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eth-artefact-set-frame-content-bottom">
                    <div className="eth-artefact-set-frame-info">
                        The “Forbidden Fruits” serie in the collection has <span className="eth-text-bright-yellow">500 tokens</span> and 10% of the tokens has the physical artefact redeemable trait
                    </div>
                    <div className="eth-artefact-set-frame-logo">
                        <img src="../../images/eth-fruits-logo.png" alt="Forbidden Fruits logo" />
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-artefact-set-qoute text-center">
            <p>I'm just like you. I enjoy the forbidden fruits in life, too.</p>

            <p>- Mike Tyson</p>
        </div>
    </div>
</div>
)
}
