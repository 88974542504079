import React from 'react';

export default function GameFruits() {
return (
<div className="eth-game-status">
    <div className="eth-game-status-inner">
        <div className="eth-game-status-title text-center">
            PRIZE POOL FILLED - GAME IS RUNNING
        </div>
        <div className="eth-game-status-frame">
            <div className="eth-game-status-frame-img">
                <img src="../../images/eth-gold-frame-game.png" alt="Gold frame" />
            </div>
            <div className="eth-game-status-frame-content">
                <div className="eth-game-status-frame-content-top">
                    <div className="eth-game-status-frame-content-top-cards">
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-fruits-token-icon-1.png" alt="Forbidden fruits token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-fruits-token-icon-2.png" alt="Forbidden fruits token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-fruits-token-icon-3.png" alt="Forbidden fruits token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card eth-game-status-frame-content-top-card-active">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-light.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-fruits-token-icon-4.png" alt="Forbidden fruits token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card eth-game-status-frame-content-top-card-active">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-light.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-fruits-token-icon-5.png" alt="Forbidden fruits token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card eth-game-status-frame-content-top-card-active">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-light.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-fruits-token-icon-6.png" alt="Forbidden fruits token icon" />
                        </div>
                    </div>
                    <div className="eth-game-status-frame-content-top-text text-center">
                        <p>You need to place 6 "Forbidden fruits" tokens from your wallet on cards of the same colour code.
                        Check the colour code of your actual token in their top  right corner.</p>

                        <p>Jackpot pays out : 50% of the actual amount of the ETH in the prize pool</p>

                        <p>The puzzle can be completed <span className="eth-text-bright-yellow">50</span> times in total. After that, the pool is exhausted and closes.</p>
                    </div>
                </div>
                <div className="eth-game-status-frame-content-bottom">
                    <div className="eth-game-status-frame-content-bottom-first">
                        <div className="eth-game-status-frame-content-bottom-first-logo">
                            <img src="../../images/eth-fruits-logo.png" alt="Forbidden fruits logo" />
                        </div>
                        <div className="eth-game-status-frame-content-bottom-first-text">
                            Artefact serial numbers 096-044
                        </div>
                    </div>
                    <div className="eth-game-status-frame-content-bottom-last">
                        <span className="eth-text-bright-yellow">Pool total:</span> 2,7862 ETH
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}
