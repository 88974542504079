import React from 'react';

export default function Birds() {
return (
<div className="eth-artefact-set">
    <div className="eth-artefact-set-inner">
        <div className="eth-artefact-set-frame">
            <div className="eth-artefact-set-frame-img">
                <img src="../../images/eth-gold-frame.png" alt="Gold frame" />
            </div>
            <div className="eth-artefact-set-frame-content">
                <div className="eth-artefact-set-frame-content-top">
                    <div className="eth-artefact-set-frame-content-top-left">
                        <div className="eth-artefact-set-frame-tokens">
                            <img src="../../images/eth-birds-tokens.png" alt="Birds of Shambala tokens" />
                        </div>
                        <div className="eth-artefact-set-frame-variations text-center">
                            <div className="eth-artefact-set-frame-variations-text">
                                Variations in the artefact set
                            </div>
                            <div className="eth-artefact-set-frame-variations-icons">
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-birds-token-icon-1.png" alt="Birds of Shambala artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        12
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-birds-token-icon-2.png" alt="Birds of Shambala artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        20
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-birds-token-icon-3.png" alt="Birds of Shambala artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        20
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-birds-token-icon-4.png" alt="Birds of Shambala artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        19
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-birds-token-icon-5.png" alt="Birds of Shambala artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        19
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-birds-token-icon-6.png" alt="Birds of Shambala artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        9
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-content-top-right">
                        <div className="eth-artefact-set-frame-counter">
                            <div className="eth-artefact-set-frame-counter-img">
                                <img src="../../images/buypage_tokencounter_keret.png" alt="Artefact counter frame" />
                            </div>
                            <div className="eth-artefact-set-frame-counter-data text-center">
                                <div className="eth-artefact-set-frame-counter-data-number eth-text-bright-yellow">
                                    99
                                </div>
                                <div className="eth-artefact-set-frame-counter-data-text">
                                    artefacts
                                </div>
                            </div>
                        </div>
                        <div className="eth-artefact-set-frame-tokens-data text-center">
                            <div className="eth-artefact-set-frame-tokens-data-serial">
                                Serial Numbers: 296-196
                            </div>
                            <div className="eth-artefact-set-frame-tokens-data-utility">
                                Utility traits: 4
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eth-artefact-set-frame-content-bottom">
                    <div className="eth-artefact-set-frame-info">
                        The “Birds of Shambala” serie in the collection has <span className="eth-text-bright-yellow">990 tokens</span> and 10% of the tokens has the physical artefact redeemable trait
                    </div>
                    <div className="eth-artefact-set-frame-logo">
                        <img src="../../images/eth-birds-logo.png" alt="Birds of Shambala logo" />
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-artefact-set-qoute text-center">
            <p>"Everyone likes birds. What wild creature is more accessible to our eyes and ears,
            as close to us and everyone in the world, as universal as a bird?"</p>

            <p>- Sir David Attenborough</p>
        </div>
    </div>
</div>
)
}
