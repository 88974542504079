import React from 'react';

export default function GrandPrize() {
return (
<div className="eth-artefact-set">
    <div className="eth-artefact-set-inner">
        <div className="eth-artefact-set-frame">
            <div className="eth-artefact-set-frame-img">
                <img src="../../images/eth-gold-frame.png" alt="Gold frame" />
            </div>
            <div className="eth-artefact-set-frame-content">
                <div className="eth-artefact-set-frame-content-top">
                    <div className="eth-artefact-set-frame-content-top-left">
                        <div className="eth-artefact-set-frame-tokens">
                            <img src="../../images/eth-grandprize-tokens.png" alt="Grand Prize tokens" />
                        </div>
                        <div className="eth-artefact-set-frame-variations text-center">
                            <div className="eth-artefact-set-frame-variations-text">
                            The grand prize have a special logo marking
                            </div>
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-content-top-right">
                        <div className="eth-artefact-set-frame-counter">
                            <div className="eth-artefact-set-frame-counter-img">
                                <img src="../../images/buypage_tokencounter_keret.png" alt="Artefact counter frame" />
                            </div>
                            <div className="eth-artefact-set-frame-counter-data text-center">
                                <div className="eth-artefact-set-frame-counter-data-number eth-text-bright-yellow">
                                    1
                                </div>
                                <div className="eth-artefact-set-frame-counter-data-text">
                                    artefact
                                </div>
                            </div>
                        </div>
                        <div className="eth-artefact-set-frame-tokens-data text-center">
                            <div className="eth-artefact-set-frame-tokens-data-yellow eth-text-bright-yellow">
                                The grand prize can only be won, not bought.
                            </div>
                            Check the winning conditions in the DETAILED FAQ
                        </div>
                    </div>
                </div>
                <div className="eth-artefact-set-frame-content-bottom">
                    <div className="eth-artefact-set-frame-info">
                        <div className="eth-artefact-set-frame-info-left">
                            Number of utility traits: 12
                        </div>
                        <div className="eth-artefact-set-frame-info-right">
                            Serial Number: 000
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-logo">
                        <img src="../../images/eth-grandprize-logo.png" alt="Grand Prize logo" />
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-artefact-set-qoute text-center">
            <p>I cannot believe that the most delicious things were placed here merely to test us, to tempt us,
            to make it the more  difficult for us to capture the grand prize.”</p>

            <p>Tom Robins in Jitterbug Parfume</p>
        </div>
    </div>
</div>
)
}
