import React from 'react';

export default function WildFlowers() {
return (
<div className="eth-artefact-set">
    <div className="eth-artefact-set-inner">
        <div className="eth-artefact-set-frame">
            <div className="eth-artefact-set-frame-img">
                <img src="../../images/eth-gold-frame.png" alt="Gold frame" />
            </div>
            <div className="eth-artefact-set-frame-content">
                <div className="eth-artefact-set-frame-content-top">
                    <div className="eth-artefact-set-frame-content-top-left">
                        <div className="eth-artefact-set-frame-tokens">
                            <img src="../../images/eth-wild-flowers-tokens.png" alt="Wild Flowers of Atlantis tokens" />
                        </div>
                        <div className="eth-artefact-set-frame-variations text-center">
                            <div className="eth-artefact-set-frame-variations-text">
                                Variations in the artefact set
                            </div>
                            <div className="eth-artefact-set-frame-variations-icons">
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-wild-flowers-token-icon-1.png" alt="Wild Flowers of Atlantis artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        12
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-wild-flowers-token-icon-2.png" alt="Wild Flowers of Atlantis artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        20
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-wild-flowers-token-icon-3.png" alt="Wild Flowers of Atlantis artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        20
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-wild-flowers-token-icon-4.png" alt="Wild Flowers of Atlantis artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        19
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-wild-flowers-token-icon-5.png" alt="Wild Flowers of Atlantis artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        19
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-wild-flowers-token-icon-6.png" alt="Wild Flowers of Atlantis artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        10
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-content-top-right">
                        <div className="eth-artefact-set-frame-counter">
                            <div className="eth-artefact-set-frame-counter-img">
                                <img src="../../images/buypage_tokencounter_keret.png" alt="Artefact counter frame" />
                            </div>
                            <div className="eth-artefact-set-frame-counter-data text-center">
                                <div className="eth-artefact-set-frame-counter-data-number eth-text-bright-yellow">
                                    100
                                </div>
                                <div className="eth-artefact-set-frame-counter-data-text">
                                    artefacts
                                </div>
                            </div>
                        </div>
                        <div className="eth-artefact-set-frame-tokens-data text-center">
                            <div className="eth-artefact-set-frame-tokens-data-serial">
                                Serial Numbers: 499-398
                            </div>
                            <div className="eth-artefact-set-frame-tokens-data-utility">
                                Utility traits: 3
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eth-artefact-set-frame-content-bottom">
                    <div className="eth-artefact-set-frame-info">
                        The “Wild Flowers of Atlantis” serie in the collection has <span className="eth-text-bright-yellow">1000 tokens</span> and 10% of the tokens has the physical artefact redeemable trait
                    </div>
                    <div className="eth-artefact-set-frame-logo">
                        <img src="../../images/eth-wild-flowers-logo.png" alt="Wild Flowers of Atlantis logo" />
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-artefact-set-qoute text-center">
            <p>"You belong among the wild flowers You belong in a boat out at sea
            You belong with your love on your arm You belong somewhere you feel free"</p>

            <p>- Tom Petty.</p>
        </div>
    </div>
</div>
)
}
