import React from 'react';
import ReactDOM from 'react-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function Tokens() {
return (
<div className="eth-tokens">
    <div className="eth-tokens-inner">
        <div className="eth-tokens-frame">
            <div className="eth-tokens-frame-img">
                <img src="../../images/eth-gold-frame-simple.png" alt="Gold frame" />
            </div>
            <div className="eth-tokens-frame-content">
                <div className="eth-tokens-frame-content-left text-center">
                    <p className="eth-text-yellow">The bid price increases in inverse
                        proportion to the amount of tokens.
                        In other words, the fewer tokens in
                        the supply, the higher the offer
                        price will be.</p>

                    <p className="eth-text-orange">Only 5000 tokens, and
                        500 associated artefacts are available.</p>

                    <div className="eth-text-yellow">
                        <div>
                            1st <span className="eth-text-orange">1000</span> tokens for <span className="eth-text-orange">0,19 ETH</span>
                        </div>
                        <div>
                            2nd <span className="eth-text-orange">1000</span> tokens for <span className="eth-text-orange">0,39 ETH</span>
                        </div>
                        <div>
                            3rd <span className="eth-text-orange">1000</span> tokens for <span className="eth-text-orange">0,59 ETH</span>
                        </div>
                        <div>
                            <span className="eth-text-orange">990</span> tokens for <span className="eth-text-orange">0,79 ETH</span>
                        </div>
                        <div>
                            <span className="eth-text-orange">500</span> tokens for <span className="eth-text-orange">0,99 ETH</span>
                        </div>
                        <div>
                            <span className="eth-text-orange">360</span> tokens for <span className="eth-text-orange">1,49 ETH</span>
                        </div>
                        <div>
                            last <span className="eth-text-orange">150</span> tokens for <span className="eth-text-orange">1,99 ETH</span>
                        </div>
                    </div>

                    <p className="eth-text-yellow">Everyone has an equal chance of getting
                        one or more from the collection and
                        participate in the collectible metagames,
                        which has serious prizes.</p>

                    <p className="eth-text-orange">First come, first served!</p>
                </div>
                <div className="eth-tokens-frame-content-right">
                    <div className="eth-tokens-frame-tokencounter">
                        <div className="eth-tokens-frame-tokencounter-img">
                            <img src="../../images/buypage_tokencounter_keret.png" alt="Token counter frame" />
                        </div>
                        <div className="eth-tokens-frame-tokencounter-data text-center">
                            <div className="eth-tokens-frame-tokencounter-data-text">
                                available tokens
                            </div>
                            <div className="eth-tokens-frame-tokencounter-data-number eth-text-bright-yellow">
                                5000
                            </div>
                            <div className="eth-tokens-frame-tokencounter-data-text">
                                pieces
                            </div>
                        </div>
                    </div>
                    <div className="eth-tokens-frame-discount text-center">
                        <div className="eth-tokens-frame-discount-text-first">
                            1st 1000 tokens for
                        </div>
                        <div className="eth-tokens-frame-discount-text-value">
                            0,09 ETH
                        </div>
                        <div className="eth-tokens-frame-discount-text-last eth-text-bright-yellow">
                            5000-4001
                        </div>
                    </div>
                    <div className="eth-tokens-frame-bottom">
                        <div className="text-center">
                            increase to 0,19 ETH at <span className="eth-text-bright-yellow">4000</span>
                        </div>
                        <div className="eth-tokens-frame-buy-button">
                            <a href="#">
                                <img src="../../images/buypage_button_BUY_CLOSE_base.png" alt="Buy close button" />
                            </a>
                        </div>
                        <div className="text-center">
                            SALE PERIOD: 20.8.2022 - 31.8.2022
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-tokens-buttons">
            <div className="eth-tokens-button">
                <AnchorLink href="#detailed-faq" className="eth-button">Detailed FAQ</AnchorLink>
            </div>
            <div className="eth-tokens-button">
                <a href="#" className="eth-button">Connect Wallet</a>
            </div>
            <div className="eth-tokens-button">
                <AnchorLink href="#meta-games" className="eth-button">Metagames</AnchorLink>
            </div>
        </div>
    </div>
</div>
)
}
