import React from 'react';

export default function Stars() {
return (
<div className="eth-artefact-set">
    <div className="eth-artefact-set-inner">
        <div className="eth-artefact-set-frame">
            <div className="eth-artefact-set-frame-img">
                <img src="../../images/eth-gold-frame.png" alt="Gold frame" />
            </div>
            <div className="eth-artefact-set-frame-content">
                <div className="eth-artefact-set-frame-content-top">
                    <div className="eth-artefact-set-frame-content-top-left">
                        <div className="eth-artefact-set-frame-tokens">
                            <img src="../../images/eth-stars-tokens.png" alt="Gold of the Dying Stars tokens" />
                        </div>
                        <div className="eth-artefact-set-frame-variations text-center">
                            <div className="eth-artefact-set-frame-variations-text">
                                Variations in the artefact set
                            </div>
                            <div className="eth-artefact-set-frame-variations-icons">
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-stars-token-icon-1.png" alt="Gold of the Dying Stars artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        3
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-stars-token-icon-2.png" alt="Gold of the Dying Stars artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        9
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-stars-token-icon-3.png" alt="Gold of the Dying Stars artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        8
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-stars-token-icon-4.png" alt="Gold of the Dying Stars artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        6
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-stars-token-icon-5.png" alt="Gold of the Dying Stars artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        5
                                    </div>
                                </div>
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-stars-token-icon-6.png" alt="Gold of the Dying Stars artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        5
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-content-top-right">
                        <div className="eth-artefact-set-frame-counter">
                            <div className="eth-artefact-set-frame-counter-img">
                                <img src="../../images/buypage_tokencounter_keret.png" alt="Artefact counter frame" />
                            </div>
                            <div className="eth-artefact-set-frame-counter-data text-center">
                                <div className="eth-artefact-set-frame-counter-data-number eth-text-bright-yellow">
                                    36
                                </div>
                                <div className="eth-artefact-set-frame-counter-data-text">
                                    artefacts
                                </div>
                            </div>
                        </div>
                        <div className="eth-artefact-set-frame-tokens-data text-center">
                            <div className="eth-artefact-set-frame-tokens-data-serial">
                                Serial Numbers: 043-011 + Specials
                            </div>
                            <div className="eth-artefact-set-frame-tokens-data-utility">
                                Utility traits: 6
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eth-artefact-set-frame-content-bottom">
                    <div className="eth-artefact-set-frame-info">
                        The “Gold of the Dying Stars” serie in the collection has <span className="eth-text-bright-yellow">360 tokens</span> and 10% of the tokens has the physical artefact redeemable trait
                    </div>
                    <div className="eth-artefact-set-frame-logo">
                        <img src="../../images/eth-stars-logo.png" alt="Gold of the Dying Stars logo" />
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-artefact-set-qoute text-center">
            <p>“The nitrogen in our DNA, the calcium in our teeth, the iron in our blood, the carbon in
            our apple pies were made in the interiors of collapsing stars. We are made of starstuff.”</p>

            <p>- Carl Sagan, Cosmos</p>
        </div>
    </div>
</div>
)
}
