import React from 'react';

export default function GameOpens() {
return (
<div className="eth-game-status">
    <div className="eth-game-status-inner">
        <div className="eth-game-status-title text-center">
            GAME OPENS: 15.6.2022
        </div>
        <p className="text-center">To fill the pools, 5% is allocated from primary sales and 50% from secondary sales. These are used to varying degrees in the pools' prize pools. For the exact amount, check the "DETAILED FAQ" section.</p>
        <div className="eth-game-status-frame">
            <div className="eth-game-status-frame-img">
                <img src="../../images/eth-gold-frame-game-opens.png" alt="Gold frame with curtain" />
            </div>
            <div className="eth-game-status-frame-content">
                <div className="eth-game-status-frame-content-top">
                </div>
                <div className="eth-game-status-frame-content-bottom">
                    <div className="eth-game-status-frame-content-bottom-first">
                        <div className="eth-game-status-frame-content-bottom-first-logo">
                            <img src="../../images/eth-wild-flowers-logo.png" alt="Wild Flowers of Atlantis logo" />
                        </div>
                        <div className="eth-game-status-frame-content-bottom-first-text">
                            Artefact serial numbers 499-398
                        </div>
                    </div>
                    <div className="eth-game-status-frame-content-bottom-last">
                        <span className="eth-text-bright-yellow">Pool total:</span> 0,0000 ETH
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}
