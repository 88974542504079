import React from 'react';

export default function Blueprint() {
return (
<div className="eth-blueprint-wrapper">
    <div className="eth-blueprint eth-blueprint-desktop">
        <div className="eth-blueprint-top">
            <div className="eth-blueprint-top-left">
                <div className="eth-blueprint-box eth-blueprint-box-1">
                    <p>Unique, hand painted, colourful motif.
                        The collection contains 30 unique motifs,
                        grouped in 5 motif categories.</p>
                </div>
                <div className="eth-blueprint-box eth-blueprint-box-2">
                    <p>Unique, hand painted, gold plated
                        collection serial number.</p>
                </div>
            </div>
            <div className="eth-blueprint-top-center">
                <div className="eth-blueprint-image-box">
                    <div className="eth-blueprint-title eth-text-light-blue text-center">
                        Anatomy of the collection artefacts
                    </div>
                    <div className="eth-blueprint-porcelain">
                        <div className="eth-blueprint-porcelain-bg">
                            <img src="../../images/eth-porcelain-blueprint-bg.png" alt="Porcelain blueprint background" />
                        </div>
                        <div className="eth-blueprint-porcelain-img">
                            <img src="../../images/eth-porcelain.png" alt="Porcelain blueprint" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="eth-blueprint-top-right">
                <div className="eth-blueprint-box eth-blueprint-box-3">
                    <p>Each collection token features a
                        unique two-colour combination
                        branding in the top right corner.
                        This is an important part of the
                        collectible metagame associated with
                        the collection. About the game more
                        information in the "DETAILED FAQ.</p>
                </div>
                <div className="eth-blueprint-box eth-blueprint-box-4">
                    <p>Highest quality European porcelain
                        made in a manufactory, which is still
                        operating since 1826 in the heart
                        of Europe in the city of “Herend”.</p>

                    <p>The "Herend" artefact trademark is
                        one of the most valuable trademarks
                        in the world of porcelain collectors.</p>

                    <p>This mark, with the unique
                        manufacturer's serial number can be
                        found on every physical artefact.</p>
                </div>
            </div>
        </div>
        <div className="eth-blueprint-bottom">
            <div className="eth-blueprint-box">
                <p>The "redeemable artefact" utility trait of the token is unique in the world of NFTs.
                    10% of the tokens comes with the redeemable free physical artefact, which is the same art as the base art of the token.
                    All Premier Collection Etherendy token has a digital redeemable trait as well, and free to use to make unique value your token.</p>
            </div>
        </div>
    </div>
    <div className="eth-blueprint eth-blueprint-mobile">
        <div className="eth-blueprint-first">
            <div className="eth-blueprint-first-bg">
                <img src="../../images/eth-blueprint-mobile-w-token.png" alt="Porcelain blueprint" />
            </div>
            <div className="eth-blueprint-first-content">
                <div className="eth-blueprint-first-content-top">
                    <div className="eth-blueprint-title eth-text-light-blue text-center">
                        Anatomy of the collection artefacts
                    </div>
                </div>

                <div className="eth-blueprint-box eth-blueprint-box-last">
                    <p>30 unique motifs, grouped in 5 motif categories.</p>
                    <p>Each collection token features a unique two-colour combination
                        branding in the top right corner. This is an important part of the
                        collectible metagame associated with the collection.
                        About the game more information in the "DETAILED FAQ.
                    </p>
                </div>
            </div>
        </div>

        <div className="eth-blueprint-last">
            <div className="eth-blueprint-last-bg">
                <img src="../../images/eth-blueprint-mobile.png" alt="Porcelain blueprint" />
            </div>
            <div className="eth-blueprint-last-content">
                <div className="eth-blueprint-box">
                    <p>10% of the tokens comes with the
                        redeemable free physical
                        artefact, which is the same art as
                        the base art of the token.
                        All Premier Collection Etherendy
                        token has a digital redeemable
                        trait as well.
                    </p>

                    <p>Highest quality European porcelain
                        made in a manufactory, which is still
                        operating since 1826 in the heart
                        of Europe in the city of “Herend”.
                    </p>

                    <p>The "Herend" artefact trademark is
                        one of the most valuable trademarks
                        in the world of porcelain collectors.
                    </p>

                    <p>This mark, with the unique
                        manufacturer's serial number can be
                        found on every physical artefact.
                    </p>
                </div>
                <div className="eth-blueprint-box eth-blueprint-box-last">
                    <div>Etherendy</div>
                    <p>When classic art meets with cutting edge technology
                        500 physical collection item on 5000 dynamic NFT’s
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
)
}
