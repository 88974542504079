import React from 'react';

export default function GameElDorado() {
return (
<div className="eth-game-status">
    <div className="eth-game-status-inner">
        <div className="eth-game-status-title text-center">
            PRIZE POOL FILLED - GAME IS RUNNING
        </div>
        <div className="eth-game-status-frame">
            <div className="eth-game-status-frame-img">
                <img src="../../images/eth-gold-frame-game.png" alt="Gold frame" />
            </div>
            <div className="eth-game-status-frame-content">
                <div className="eth-game-status-frame-content-top">
                    <div className="eth-game-status-frame-content-top-cards">
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado token icon" />
                        </div>
                        <div className="eth-game-status-frame-content-top-card">
                            <img className="eth-game-status-frame-content-top-card-bg" src="../../images/eth-token-card-dark.png" alt="Token card" />
                            <img className="eth-game-status-frame-content-top-card-icon" src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado token icon" />
                        </div>
                    </div>
                    <div className="eth-game-status-frame-content-top-text text-center">
                        <p>You need to place 6 "Tokens of El Dorado" tokens from your wallet on cards of the same colour code.
                        Check the colour code of your actual token in their top  right corner.</p>

                        <p>Jackpot pays out : 50% of the actual amount of the ETH in the prize pool
                        The puzzle can be completed <span className="eth-text-bright-yellow">50</span> times in total. After that, the pool is exhausted and closes.
                        <span className="eth-text-bright-yellow">This does not include the full gold logo tokens of the "Gold of the Dying Stars" group.
                        The gold logo numbered tokens that cannot be used here are: 010, 200, 400</span></p>
                    </div>
                </div>
                <div className="eth-game-status-frame-content-bottom">
                    <div className="eth-game-status-frame-content-bottom-first">
                        <div className="eth-game-status-frame-content-bottom-first-logo">
                            <img src="../../images/eth-eldorado-logo.png" alt="Tokens of El Dorado logo" />
                        </div>
                        <div className="eth-game-status-frame-content-bottom-first-text">
                            Artefact serial numbers: Extremes
                        </div>
                    </div>
                    <div className="eth-game-status-frame-content-bottom-last">
                        <span className="eth-text-bright-yellow">Pool total:</span> 7,8899 ETH
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}
