import React from 'react';

import './style.css';
import Blueprint from './components/blueprint';
import Tokens from './components/tokens';
import WildFlowers from './components/artefacts/wild-flowers';
import Roses from './components/artefacts/roses';
import Fishes from './components/artefacts/fishes';
import Birds from './components/artefacts/birds';
import Fruits from './components/artefacts/fruits';
import Stars from './components/artefacts/stars';
import ElDorado from './components/artefacts/eldorado';
import GrandPrize from './components/artefacts/grandprize';
import GameOpens from './components/games/game-opens';
import GameWildFlowers from './components/games/game-wild-flowers';
import GameRoses from './components/games/game-roses';
import GameFishes from './components/games/game-fishes';
import GameBirds from './components/games/game-birds';
import GameFruits from './components/games/game-fruits';
import GameStars from './components/games/game-stars';
import GameElDorado from './components/games/game-eldorado';

function App() {
return (
<div className="content">
    <div className="eth-bg-items">
        <div className="eth-bg-item eth-bg-item-planet eth-bg-item-planet-left">
            <img src="/images/eth-planet.png" alt="Planet" />
        </div>
        <div className="eth-bg-item eth-bg-item-planet eth-bg-item-cloud-left">
            <img src="/images/eth-nebula.png" alt="Color cloud" />
        </div>
        <div className="eth-bg-item eth-bg-item-planet eth-bg-item-planet-right">
            <img src="/images/eth-planet-2.png" alt="Planet" />
        </div>
    </div>
    <div className="section section-center section-top">
        <div className="eth-logo">
            <img src="/images/etherendy_logo.png" alt="Etherendy logo" />
        </div>
        <div className="eth-galaxis">
            <img src="/images/Powered_by_Galaxis_LOGO.png" alt="Powered_by_Galaxis logo" />
        </div>
        <div className="eth-special-price">
            Special price for al kind of “Ether Cards” holders!
        </div>
        <div className="eth-timer">
            <div className="eth-timer-top">
                <div className="eth-timer-top-item eth-timer-top-item-days">
                    <div className="eth-timer-top-item-number">
                        35
                    </div>
                    <div className="eth-timer-top-item-text">
                        Days
                    </div>
                </div>
                <div className="eth-timer-top-item eth-timer-top-item-divider eth-timer-top-item-number">
                    :
                </div>
                <div className="eth-timer-top-item eth-timer-top-item-hours">
                    <div className="eth-timer-top-item-number">
                        16
                    </div>
                    <div className="eth-timer-top-item-text">
                        Hours
                    </div>
                </div>
                <div className="eth-timer-top-item eth-timer-top-item-divider eth-timer-top-item-number">
                    :
                </div>
                <div className="eth-timer-top-item eth-timer-top-item-minutes">
                    <div className="eth-timer-top-item-number">
                        25
                    </div>
                    <div className="eth-timer-top-item-text">
                        Minutes
                    </div>
                </div>
                <div className="eth-timer-top-item eth-timer-top-item-divider eth-timer-top-item-number">
                    :
                </div>
                <div className="eth-timer-top-item eth-timer-top-item-seconds">
                    <div className="eth-timer-top-item-number">
                        57
                    </div>
                    <div className="eth-timer-top-item-text">
                        Seconds
                    </div>
                </div>
            </div>
            <div className="eth-timer-bottom">
                <div className="eth-timer-bottom-date">
                    18. 8. 2022
                </div>
            </div>
        </div>
    </div>
    <div className="section-container">
        <div className="section section-center">
            <div className="eth-subscribe">
                <div className="text-center eth-subscribe-text">
                    Subscribe for the latest Etherendy news and sale dates
                </div>
                <div className="eth-subscribe-form">
                    <div className="eth-subscribe-form-button">
                        <a href="#" className="eth-button eth-button-w-icon">
                            <span className="eth-button-icon">
                                <img src="/images/etherendy-play-icon.svg" alt="Play icon" />
                            </span>
                            <span className="eth-button-text">Subscribe</span>
                        </a>
                    </div>
                    <div className="eth-subscribe-form-field">
                        <input type="text" name="Subscribe" />
                    </div>
                </div>
            </div>
        </div>
        <div className="section section-center">
            <div className="eth-welcome text-center">
                <div className="eth-welcome-title eth-text-yellow">
                    Welcome Collector!
                </div>
                <p>The Lord, the Lady and the Dragon 
                    proudly present a new generation NFT 
                    drop where classical art meets the latest 
                    blockchain technology, ancient tradition 
                    meets the dynamic future.
                </p>
                <p className="eth-text-yellow">
                    Like no other drop in the history of NFT’s before.
                </p>
                <p>5000 tokens and 500 associated artefacts.</p>
                <p>All related artefacts are individually formed, factory
                    and collector's branding and 24 carat gold painting.</p>
                <p>Made in the heart of Europe, in the world-famous
                    Herend Porcelain Manufactory, in operation since 1826.</p>
                <p className="eth-text-yellow">The “Etherendy Premier Collection”
                    does not follow the current NFT trends.</p>
                <p className="eth-text-green">It creates.</p>
            </div>
        </div>
        <div className="section section-blueprint">
            <Blueprint />
        </div>
        <div className="section section-tokens">
            <Tokens />
        </div>
        <div className="section section-center section-high">
            <div className="eth-large-title text-center">
                The following pages present the artefact collection
            </div>
        </div>
        <div className="section section-artefacts section-artefacts-wildflowers">
            <WildFlowers />
        </div>
        <div className="section section-artefacts section-artefacts-roses">
            <Roses />
        </div>
        <div className="section section-artefacts section-artefacts-fishes">
            <Fishes />
        </div>
        <div className="section section-artefacts section-artefacts-birds">
            <Birds />
        </div>
        <div className="section section-artefacts section-artefacts-fruits">
            <Fruits />
        </div>
        <div className="section section-artefacts section-artefacts-stars">
            <Stars />
        </div>
        <div className="section section-artefacts section-artefacts-eldorado">
            <ElDorado />
        </div>
        <div className="section section-artefacts section-artefacts-grandprize">
            <GrandPrize />
        </div>
        <div className="section section-center section-high" id="meta-games">
            <div className="eth-large-title text-center">
                The following pages present the metagames
            </div>
        </div>
        <div className="section section-game section-game-opens">
            <GameOpens />
        </div>
        {/* <div className="section section-game section-game-wild-flowers">
            <GameWildFlowers />
        </div> */}
        <div className="section section-game section-game-roses">
            <GameRoses />
        </div>
        <div className="section section-game section-game-fishes">
            <GameFishes />
        </div>
        <div className="section section-game section-game-birds">
            <GameBirds />
        </div>
        <div className="section section-game section-game-fruits">
            <GameFruits />
        </div>
        <div className="section section-game section-game-stars">
            <GameStars />
        </div>
        <div className="section section-game section-game-eldorado">
            <GameElDorado />
        </div>
        <div className="section section-empty-divider">
        </div>
        <div className="section section-faq" id="detailed-faq">
            <div className="faq-title eth-large-title text-center">
                Detailed FAQ
            </div>

            <p><strong>1. What is “Etherendy”?</strong></p>

            <p>"Etherendy" is a brand of  non fungible tokens and its collections, or "NFT'S" for short, created according to the ERC721 standard. Each "Etherendy" token is linked to one or more real artistic physical artefacts, and/or it's digital existing form.</p>

            <p>Etherendy is the first NFT brand to create a link between classical, traditional art collections and cutting-edge blockchain technology.</p>

            <p><strong>2. What is "Etherendy - Premier Collection"?</strong></p>

            <p>The “Etherendy Premier Collection” consists of 5,000 NFT tokens, accompanied by a highly artistic porcelain tile collection of no less than 500 artefact pieces.</p>

            <p>The physical pieces of the collection are handmade and hand painted in Europe's most famous porcelain manufactory, the Herend Porcelain Manufactory, located in the heart of Europe, in the town of Herend, and still operating since 1826.</p>

            <p>Each physical piece has a unique factory branding and registration number, which can be retrieved at any time by entering it in the perpetual register of the manufactory, which has been kept for 196 years.</p>

            <p>The "Herend" porcelain mark is one of the most valuable among the world's porcelain art collectors, so it gives your token a guaranteed value, so its value is also secure.</p>

            <p>In the “Premier Collection” all physical works of art also exist in digital form as computer animations in MP4 format.</p>

            <p>Only 10% of the “Etherendy - Premier Collection” tokens have a physically redeemable "utility trait" (500pcs out of 5000 tokens) which allows the token holder to claim the valuable porcelain artefact for free from the campaign's operators as the part of his/her NFT piece.</p>

            <p><strong>3. What else is there to know about the physical artefacts in the "Etherendy - Premier Collection" ?</strong></p>

            <ul>
                <li>Each piece is original, unique, hand-painted, hand-gilded and numbered.</li>
                <li>Size 35x45mm, thickness approx.3mm at the thickest part. The motif set is divided into 5 main groups, each motif set contains 6pcs unique designs.</li>
                <li>The names and piece numbers of the motif set (and the amount of the available utility traits on it) are in the physical tokens:</li>
                <ul>
                    <li>Wild Flowers of Atlantis - 100 pieces (3 utility traits)</li>
                    <li>Roses of Jah - 100 pieces (3 utility traits)</li>
                    <li>Birds of Shambala - 100 pieces (3 utility traits)</li>
                    <li>Fishes of Gaia - 99 pieces (4 utility traits)</li>
                    <li>Forbidden Fruits - 50 pieces (5 utility traits)</li>
                    <li>Gold of the Dying Stars - 36 pieces (6 utility traits)</li>
                    <li>Tokens of El Dorado - 15 pieces (7 utility traits)</li>
                </ul>
                <li>The digital tokens , which give the owner an animated version of the artefacts, are ten times as numerous in the collection.</li>
                <li>Each physical item has 9 additional official collection copies as NFT's. So, for every 10 tokens with the same symbol set and logo variant, 1 of them corresponds to a physical artifact.</li>
                <li>The total collection includes 4500 tokens with digital-only and 500 digital AND physical artifacts.</li>
            </ul>

            <p><strong>4. What other utility treats are on the tokens ?</strong></p>

            <p>Percentage discounts for the next “Etherendy” NFT collections, ranging from 10-50%.</p>

            <p>Physical artifact redemption (on 10% of tokens)</p>

            <p>Digital artefact redeemability (the token will be 2 sides)</p>

            <p>"Usability" token for the collectible metagame</p>

            <p>Other ticket functions for NFT-based events and games in the Galaxis NFT metaverse. (to be announced later)</p>

            <p><strong>5. How can I buy one or more tokens from the Etherendy Premier Collection ?</strong></p>

            <p>It's easy, as 1,2,3 !</p>

            <ul>
                <li>Connect your wallet with Ether in it</li>
                <li>Press the “BUY” button on the main shopping screen</li>
                <li>On the following panel, set the amount of tokens you want to buy</li>
                <li>Press the “FINALISE MY PURCHASE” button.</li>
                <li>You are done.</li>
            </ul>

            <p><strong>6. How many tokens can I buy at a time ?</strong></p>

            <p>The maximum number of tokens you can purchase in a transaction is limited to 50.</p>
            <p>This gives everyone a chance to buy at least one token before the available stocks are exhausted.</p>

            <p><strong>7.When do I hook the tokens into my wallet ?</strong></p>

            <p>Tokens are revealed and transferred to your wallet on the 5th day after the end of the main sale campaign.</p>

            <p>The campaign runs from 5/5/2022 to 5/15/2022.</p>
            <p>Reveal time is : 20/5/2022 - 0:00 GMT</p>

            <p><strong>8. I am an Ether Cards holder, do I get a discount in the campaign ?</strong></p>
            <p>If you have any Ether Cards in your wallet (Creator/OG/Alpha/Founder), you will get a 10% discount on the price of the tokens during the whole campaign.</p>

            <p><strong>9. Will there be a pre-sale ?</strong></p>
            There will be no pre-sale, we will focus on the main sale.
            Ether Cards holders will receive a 10% discount throughout the campaign, with no time or quantity limit.

            <p><strong>10. I changed my mind, I don't need the "Etherendy" token I bought. Can I request a refund ?</strong></p>

            <p>Short answer : no.</p>

            <p>All purchased tokens remain yours until you sell them to someone else. The organizers and etherendy.com will not buy back tokens from you. All sales and all purchases are final and non-refundable.</p>

            <p>If you want to sell your tokens, you can do so on the "Etherendy" project page of the Galaxis marketplace or on the Open Sea page, there will surely be people interested in the collection's  pieces.</p>

            <p><strong>11. Pricing of the “Etherendy - Premier Collection” tokens</strong></p>

            <p>The bid price increases in inverse proportion  to the amount of tokens.</p>

            <p>In other words, the fewer tokens in the supply,  the higher the offer price will be.</p>

            <ul>
                <li>1st 1000 tokens for <strong>0,19 ETH</strong> each</li>
                <li>2nd 1000 tokens for <strong>0,39 ETH</strong> each</li>
                <li>3rd 1000 tokens for <strong>0,59 ETH</strong> each</li>
                <li>990 tokens for <strong>0,79 ETH</strong> each</li>
                <li>500 tokens for <strong>0,99 ETH</strong> each</li>
                <li>360 tokens for <strong>1,49 ETH</strong> each</li>
                <li>last 150 tokens for <strong>1,99 ETH</strong> each</li>
            </ul>

            <p>Everyone has an equal chance of getting one or  more from the collection and participate in the collectible metagames, which has serious prizes.</p>

            <p>We have one rule over all : First come, first served!</p>

            <p><strong>12. The logotype variations of the tokens. Base of the metagames.</strong></p>

            <p className="faq-img faq-screenshot"><img src="/images/wild_flowers_artefact_screenshot.png" alt="Wild Flowers of Atlantis artefact tokens screenshot" /></p>

            <p>When presenting the physical artefact collection groups, we will indicate on the campaign website the number of logotype variants present in the given motif group.</p>

            <p>Check the “Variants of the artefacts set” group in the left side of the picture in the top. The logotype is shown in the top right corner of every “Etherendy Premier Collection” token.</p>

            <p>You can also see in the image that the purple level was used to indicate the Wildflowers group logo. The elements of the Wildflowers group always have a purple bottom logo and a complementary top colour.</p>

            <p>As you can see in this picture, there are 12 full purple elements (both the top and bottom of the logo are purple) and 10 variations with a gold top. These logo versions have an important meaning in the collectible metagame.
            In the total token set, these variants occur in 10 times the amount.</p>

            <p>To stay with the example : In the Atlantis wildflowers group, of which there are 1000 pieces in terms of total wildfowers tokens (and thus 10% of it, 100 pcs physical artifacts in total are linked), there are 120 full purple and 100 gold top logo variants.</p>

            <p><strong>13. When does the metagame start ?</strong></p>

            <p>The metagame starts on 1/6/2022 and will end on : 31/12/2022,</p>

            <p>The metagame will end earlier if all prizes have been won and there are no more prizes to be won.</p>

            <p><strong>14. Who can participate in the metagame ?</strong></p>

            <p>Anyone who has at least 1 piece “Etherendy Premier Collection” token in their wallet. Each token of the premier collection includes a single-use ticket utility trait for the Metagame.</p>

            <p><strong>15. The collectible metagame</strong></p>

            <p>The unique logo marking system of the “Etherendy Premier Collection” is the basis for a great metagame.</p>

            <p>As explained earlier, each motif group has a unique logo marking specific to that group. The Wildflowers group has purple as the primary colour for logo marking.</p>

            <p>In the collectible metagame, these groups and their corresponding logo markings have been placed on a separate play area where the game itself will take place.</p>

            <p>The Wildflowers group in the Collectible Metagame looks like this:</p>

            <p className="faq-img faq-screenshot"><img src="/images/wild_flowers_game_screenshot.png" alt="Wild Flowers of Atlantis game screenshot" /></p>

            <p>In this example the dark cards show what other logo colour scheme tokens need to be inserted to complete the row. The light cards are placed.</p>

            <p>The goal is simple: collect all the elements of the given group (6 tokens per group) and insert them into the matching card slot. Once you have done this, you will be rewarded with the 50% from the actual ETH amount from the group pool! (The last filler will have all the remaining ETH amount from the pool, this is the only one 100% payout)</p>

            <p>The rows of a given group can be unloaded at a given time. The Wildflowers row can be unloaded 100 times, as there are 100 tokens in the “Wild Flowers of Atlantis” token group with a gold-topped logo. So the whole row can be unloaded 100 times in this group and this row will decrease with each unloading.</p>

            <p>In the game, a total of 3090 tokens can be used to unload a line in all the 7 “Etherendy - Premier Collection” groups, before the pools are exhausting.</p>

            <p>Useful Tips for play the Collectible Metagame:</p>

            <ul>
                <li>In the main sale it is therefore worth buying 6 or “more x 6 “ tokens, as you will need at least 6 tokens to win one of the rows in the game.</li>

                <li>If you're looking for a single token to finish a row, we recommend bidding for the missing pieces in the collection on the secondary marketplaces.</li>

                <li>If you can buy fewer tokens than 6pcs, you can make a nice profit on the secondary marketplaces by selling your tokens to the bigger players.</li>

                <li>Special tokens with all-gold markings come with a lot of utility traits and the pools in which these tokens can be used have higher profit margins, so they can be sold for more than the average price on the secondary marketplaces. Keep it in mind and you will be a winner!</li>

                <li>Logo versions that have the same colour top and bottom (not gold, but any other colour in the given group) also have fewer tokens in their respective token groups.</li>

                <li>Therefore, in addition to full and partial gold logo variations, these are also among the more valuable ones, as they are the second least valuable in the respective groups.</li>

                <li>The fewer the number of a given logo colour, the more valuable they are to the token holder.</li>

                <li>For example, the Wildflowers group has only 120 full purple logo variants out of the 5,000 available tokens, and only 12 of the 500 physical artefacts have this logo mark.</li>
            </ul>

            <p><strong>16. The Grand Prize</strong></p>
            <p>The collection has a special element, which bears a specific, unique serial number. This is the so-called "prototype piece" , serial number 000/500</p>

            <p className="faq-img faq-grand-prize-card"><img src="/images/grand_prize_screenshot.png" alt="Grand prize card screenshot" /></p>

            <p>This token is a special piece and this is the grand prize of the “Etherendy- Premier Collection” - Collectible Metagame.</p>

            <p>There is only 1 of these, it also includes a physical artefact.</p>
            <p>Unique, one of a kind motif</p>
            <p>It has 12 utility traits</p>
            <p>This token receives 5% royalties from all other “Etherendy Premier Collection” token sales on the secondary markets, so there is a constantly growing prize pool in the wallet associated with the token.</p>

            <p>The Grand Prize token:</p>

            <p><strong>17. How can you win the Grand Prize ?</strong></p>

            <p>To win the Grand Prize of the Collectible Metagame, you need to complete the lines belonging to any group 125 times out of a possible 515 times. You'll need at least 750 tokens to do this, but a big prize requires a big investment, doesn't it ?</p>
            <p>This is for the serious players only.</p>

            <p>The Grand Prize collects 5% of the secondary sales of all “Etherendy Premier Collection” tokens until the Grand Prize is taken.</p>

            <p>Whether this will last for 3 months or 300 years is not known in advance, but there is no time limit on the possibility of winning the Grand Prize.</p>

            <p>If no-one has managed to clear the group rows at least 125 times (and thus clear the associated prize pools 125 times), but the clearance runs out, the prize pool collection from the secondary marketplaces in the pool will end.</p>

            <p>If the Grand Prize tokens not won, we will be used in a future “Etherendy” event, or perhaps auctioned off at a later date, together with the collected amount of the collected royalties.</p>

            <p><strong>18. How are the prize pools created and filled up in the Collectible Metagame ?</strong></p>

            <p>The pools are filled from the campaign proceeds at the beginning of the game. We distribute 5% of the total main sales to the pools so that there is a prize pool to be won when the metagame starts. (1/6/2022)</p>

            <p>This 5% will be distributed between the pools as follows:</p>

            <ul>
                <li>Wild Flowers of Atlantis - 0,25%</li>
                <li>Roses of Jah - 0.25%</li>
                <li>Birds of Shambala - 0,5%</li>
                <li>Fishes of Gaia - 0.5%</li>
                <li>Forbidden Fruits - 0.75%</li>
                <li>Gold of the Dying Stars - 1.25%</li>
                <li>Tokens of El Dorado – 1.5%</li>
            </ul>

            <p>These pools are then continuously replenished from the royalty fund generated by secondary sales.</p>

            <p>45% of the royalty income from the token sales on the secondary marketplaces will be reallocated to the prize pools (+5% to the Grand Prize) on an ongoing basis until the end of the Collectible Metagame.</p>

            <p>This 45% is distributed as follows:</p>

            <ul>
                <li>Wild Flowers of Atlantis - 4%</li>
                <li>Roses of Jah - 4%</li>
                <li>Birds of Shambala - 4%</li>
                <li>Fishes of Gaia - 4%</li>
                <li>Forbidden Fruits - 7%</li>
                <li>Gold of the Dying Stars - 10%</li>
                <li>Tokens of El Dorado - 12%</li>
                <p>------------------------------------------------</p>
                <li>Grand Prize - 5%</li>
            </ul>

            <p><strong>19. The serial number distribution for physical artefacts, broken down into groups.</strong></p>

            <p className="faq-img faq-serial-numbers"><img src="/images/SerrialNumbers_FIN_wLogo.jpg" alt="Etherendy Serial numbers" /></p>

            <p><strong>20. “Etherendy” - timeline for 2022</strong></p>

            <p className="faq-img faq-timeline"><img src="/images/Timeline_2022.jpg" alt="Etherendy timeline" /></p>
        </div>
    </div>
</div>
);
}

export default App;
