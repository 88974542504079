import React from 'react';

export default function ElDorado() {
return (
<div className="eth-artefact-set">
    <div className="eth-artefact-set-inner">
        <div className="eth-artefact-set-frame">
            <div className="eth-artefact-set-frame-img">
                <img src="../../images/eth-gold-frame.png" alt="Gold frame" />
            </div>
            <div className="eth-artefact-set-frame-content">
                <div className="eth-artefact-set-frame-content-top">
                    <div className="eth-artefact-set-frame-content-top-left">
                        <div className="eth-artefact-set-frame-tokens">
                            <img src="../../images/eth-eldorado-tokens.png" alt="Tokens of El Dorado tokens" />
                        </div>
                        <div className="eth-artefact-set-frame-variations text-center">
                            <div className="eth-artefact-set-frame-variations-text">
                                Variations in the artefact set
                            </div>
                            <div className="eth-artefact-set-frame-variations-icons">
                                <div className="eth-artefact-set-frame-variations-icon">
                                    <div className="eth-artefact-set-frame-variations-icon-img">
                                        <img src="../../images/eth-eldorado-token-icon.png" alt="Tokens of El Dorado artefact icon" />
                                    </div>
                                    <div className="eth-artefact-set-frame-variations-icon-num">
                                        15
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eth-artefact-set-frame-content-top-right">
                        <div className="eth-artefact-set-frame-counter">
                            <div className="eth-artefact-set-frame-counter-img">
                                <img src="../../images/buypage_tokencounter_keret.png" alt="Artefact counter frame" />
                            </div>
                            <div className="eth-artefact-set-frame-counter-data text-center">
                                <div className="eth-artefact-set-frame-counter-data-number eth-text-bright-yellow">
                                    15
                                </div>
                                <div className="eth-artefact-set-frame-counter-data-text">
                                    artefacts
                                </div>
                            </div>
                        </div>
                        <div className="eth-artefact-set-frame-tokens-data text-center">
                            <div className="eth-artefact-set-frame-tokens-data-serial">
                                Serial Numbers: EXTREMES
                            </div>
                            <div className="eth-artefact-set-frame-tokens-data-utility">
                                Utility traits: 7
                            </div>
                        </div>
                    </div>
                </div>
                <div className="eth-artefact-set-frame-content-bottom">
                    <div className="eth-artefact-set-frame-info">
                        The “Tokens of El Dorado” serie in the collection has <span className="eth-text-bright-yellow">150 tokens</span> and 10% of the tokens has the physical artefact redeemable trait
                    </div>
                    <div className="eth-artefact-set-frame-logo">
                        <img src="../../images/eth-eldorado-logo.png" alt="Tokens of El Dorado logo" />
                    </div>
                </div>
            </div>
        </div>
        <div className="eth-artefact-set-qoute text-center">
            <p>Gaily bedight, A gallant knight, In sunshine and in shadow,
            Had journeyed long, Singing a song, In search of El Dorado.</p>

            <p>- Edgar Allan Poe</p>
        </div>
    </div>
</div>
)
}
